<template>
  <section class="en-m-goods-comments m-scroll">
    <!-- 头部导航 -->
    <m-header title="Customer reviews">
      <router-link to="/en/cart" slot="right" class="solt-right"><img src="@/assets/comment/gouwu@2x.png"  alt="cart"></router-link>
    </m-header>
    <van-list v-model="loading" :finished="finished"  @load="onLoad" :immediate-check="false">
      <comment-list :data="list"></comment-list>
      <p class="search-no" v-if="list.length == 0 && !loading">No More Customer reviews</p>
    </van-list>
  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'
import CommentList  from '@/components/en/goods-comment.vue'

import { getComment } from '@/api/en/detail.js'
import { parseTime } from '@/untils/js/common.js'
export default {
  name:'Index',
  components:{ MHeader, CommentList },
  data(){
    return {
      loading:false,
      finished:false,
      list: [],
      page: 1
    }
  },

  mounted(){
    this.getCommentHandle()
  },

  methods:{
    onLoad(){
      this.page++
      this.loading = true
      this.getCommentHandle()
    },
    getCommentHandle() {
      // 获取评论
      getComment({page: this.page, goods_id: this.$route.params.id}).then(res => {
        if(res.code == 20000) {
          res.data.data.forEach(el => {
            el.created_at = parseTime(el.created_at)
          });
          this.list = this.list.concat(res.data.data)
          if(res.data.data.length < res.data.per_page) {
            this.finished = true
          }
        } else {
          this.finished = true
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.en-m-goods-comments {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top:44px;
  overflow: hidden;
  overflow-y: auto;
  .solt-right {
    display: inline-block;
    width: 30px;
    height: 30px;
    >img {
      width: 30px;
      height: 30px;
    }
  }
  .search-no{text-align:center;line-height:100px;font-size:12px;color:#888}
}
</style>