/** 
 *   商品详情
 */
import { get } from '@/untils/js/axios.js'

// 为你推荐商品列表显示
export const getDetailGoodsList = params => get(`/goods/recommend/details`, params)

// 获取评论
export const getComment = params => get(`/comment_list`, params)

// 获取商品详情信息
export const getGoodsInfo = (id,params) => get(`/goods/details/${id}`, params)

// 获取SKU
export const getSku = (id,params) => get(`/goods/sku-list/${id}`,params)

// 获取评论统计
export const getCommentTotal = params => get(`/comment_data`, params)

// 判断是否登录
export const checkLogin = params => get(`/login/check-token`, params)